import React, { Suspense, lazy } from 'react';
import './styles/index.css';
import './styles/App.css';
import './styles/Modern-normalize.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loading from 'components/Loading';

// Lazily load components
const Landing = lazy(() => import('./page/landing/Landing'));
const Login = lazy(() => import('./page/auth/Login'));
const ForgetPassword = lazy(() => import('./page/auth/ForgetPassword'));
const NotFound = lazy(() => import('./page/NotFound'));
const Home = lazy(() => import('./page/interns/Home'));
const Register = lazy(() => import('./page/Register'));
const Elearning = lazy(() => import('./page/eLearning'));
const AdminHome = lazy(() => import('./page/admin/AdminHome'));
const RTSHome = lazy(() => import('./page/rts/Home'));
const ApplyJob = lazy(() => import('./page/rts/ApplyJob'));

const App = () => {
  return (
    <div className='bg-white h-screen'>
      <Router>
        <ToastContainer />
        {/* Suspense component to show fallback while loading */}
        <Suspense fallback={<Loading/>}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/register" element={<Register />} />
            <Route path="/e-learning" element={<Elearning />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/login/:usid" element={<Login />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/home" element={<Home />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/rts" element={<RTSHome />} />
            <Route path="/rts/:comp" element={<RTSHome />} />
            {/* http://localhost:3000/rts/apply/job/ab33a27f-bcb3-44f1-a468-c87f2e8955e5/email */}
            <Route path="/rts/apply/job/:job_app_uuid/:applied_from" element={<ApplyJob />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
