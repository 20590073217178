import React from 'react';
import { MutatingDots,RevolvingDot , InfinitySpin,RotatingLines, TailSpin} from 'react-loader-spinner';
const Loading = () => {
    let loadingStyle={display:'flex',position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',justifyContent: 'center',alignItems: 'center',backgroundColor: 'rgba(0, 0, 0, 0.4)',zIndex: 9999,}
  return (
    <div style={loadingStyle} >
      <TailSpin color="#2563EB" height={100} width={100} />
    </div>
  );
};
export default Loading;
